import { Component } from "react";
import { Link } from "react-router-dom";
import Table from "./common/Table";
import Like from "./common/Like";
import auth from "../services/authService";

class MoviesTable extends Component {
   columns = [
      {
         path: "title",
         label: "Title",
         content: movie => (
            <Link to={`/movies/${movie._id}`}>{movie.title}</Link>
         )
      },
      { path: "genre.name", label: "Genre" },
      { path: "numberInStock", label: "Stock" },
      { path: "dailyRentalRate", label: "Rate" },
      {
         key: "like",
         content: movie => (
            <Like
               liked={movie.liked}
               onClick={() => this.props.onLike(movie)}
            />
         )
      }
   ];

   deleteColumn = {
      key: "delete",
      content: movie => {
         return (
            <button
               className='btn btn-sm btn-danger'
               onClick={() => this.props.onDelete(movie)}
            >
               Delete
            </button>
         );
      }
   };

   constructor() {
      super();
      const user = auth.getCurrentUser();
      if (user && user.isAdmin) this.columns.push(this.deleteColumn);
   }

   render() {
      const { movies, onSort, sortColumn } = this.props;

      return (
         <Table
            columns={this.columns}
            data={movies}
            sortColumn={sortColumn}
            onSort={onSort}
         />
      );
   }
}

export default MoviesTable;
